export default [
  {
    stepNumber: '1',
    stepName: 'cvent.steps.boxes',
    stepDescription: '',
    stepDisclaimer: '',
    stepCategory: 'disable_breadcumbs',
    componentType: 'CardSelection',
    options: [
      {
        from: '1',
        to: 'question1',
        title: 'cvent.cards.third_step.title',
        label: 'cvent.cards.third_step.button',
        image: '/workflow/gr_hand.jpg',
        // device: '/workflow/G4S_system.png',
        description: 'cvent.cards.third_step.description',
        //alert: 'cvent.steps.gb.alert_title',
        disabled: false,
      },
    ],
  },
  {
    stepNumber: 'question1',
    centered: true,
    stepName: 'cvent.steps.cvent_question1',
    stepDescription: 'cvent.steps.cvent_question1',
    stepDisclaimer: '',
    stepCategory: 'disable_breadcumbs',
    componentType: 'YesOrNoSelection',
    options: [
      {
        from: 'question1',
        to: 'question2',
        title: 'cvent.steps.cvent_yes',
        label: 'cvent.steps.cvent_yes',
      },
      {
        from: 'question1',
        to: 'question1AnsweredNo',
        title: 'cvent.steps.cvent_no',
        label: 'cvent.steps.cvent_no',
      },
    ],
  },
  {
    stepNumber: 'question1AnsweredNo',
    centered: true,
    stepName: 'cvent.steps.cvent_question1_answered_no',
    stepDescription: '',
    stepDisclaimer: '',
    stepCategory: 'disable_breadcumbs',
    stepImages: [],
    componentType: 'InformativeSelection',
    options: [
      {
        from: '',
        to: '',
        title: '',
        label: '',
      },
    ],
  },
  {
    stepNumber: 'question2',
    centered: true,
    stepName: 'cvent.steps.cvent_question2',
    stepDescription: 'cvent.steps.cvent_question2',
    stepDisclaimer: '',
    stepCategory: 'disable_breadcumbs',
    componentType: 'YesOrNoSelection',
    options: [
      {
        from: 'question1',
        to: 'radio_first_question',
        title: 'cvent.steps.cvent_yes',
        label: 'cvent.steps.cvent_yes',
      },
      {
        from: 'question1',
        to: 'question2AnsweredNo',
        title: 'cvent.steps.cvent_no',
        label: 'cvent.steps.cvent_no',
      },
    ],
  },
  {
    stepNumber: 'question2AnsweredNo',
    centered: true,
    stepName: 'cvent.steps.cvent_question2_answered_no',
    stepDescription: '',
    stepDisclaimer: '',
    stepCategory: 'disable_breadcumbs',
    stepImages: [],
    componentType: 'InformativeSelection',
    options: [
      {
        from: '',
        to: '',
        title: '',
        label: '',
      },
    ],
  },
  {
    stepNumber: 'radio_first_question',
    centered: true,
    stepName: 'cvent.steps.radio_first_question',
    stepDescription: '',
    stepDisclaimer: '',
    stepCategory: 'cvent.steps.cvent_product_and_therapy',
    componentType: 'RadioSelection',
    stepImages: [],
    options: [
      {
        from: 'radio_first_question',
        to: 'radio_second_question',
        title: 'cvent.steps.mm780gA',
        label: '',
      },
      {
        from: 'radio_first_question',
        to: 'radio_second_question',
        title: 'cvent.steps.mm780gB',
        label: '',
      },
      {
        from: 'radio_first_question',
        to: 'radio_second_question',
        title: 'cvent.steps.mm780gC',
        label: '',
      },
      {
        from: 'radio_first_question',
        to: 'radio_second_question',
        title: 'cvent.steps.smartguard',
        label: '',
      },
    ],
  },
  {
    stepNumber: 'radio_second_question',
    centered: true,
    stepName: 'cvent.steps.radio_second_question',
    stepDescription: '',
    stepDisclaimer: '',
    stepCategory: 'cvent.steps.cvent_finish',
    componentType: 'RadioSelection',
    stepImages: [],
    options: [
      {
        from: 'radio_second_question',
        to: 'Summary',
        title: 'cvent.steps.secondDialogOpt1',
        label: '',
      },
      {
        from: 'radio_second_question',
        to: 'Summary',
        title: 'cvent.steps.secondDialogOpt2',
        label: '',
      },
      {
        from: 'radio_second_question',
        to: 'Summary',
        title: 'cvent.steps.secondDialogOpt3',
        label: '',
      },
    ],
  },
];
