/* eslint-disable */

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';

import TextFrame from './frames/TextFrame';
import routes from '../../helpers/routes';

import { addTags } from '../../actions/tags';
import Button from '@material-ui/core/Button';

import esPic1 from '../../assets/es/flow/esPic1.png';
import esPic2 from '../../assets/es/flow/esPic2.png';
import esPic3 from '../../assets/es/flow/esPic3.png';
import esPic4 from '../../assets/es/flow/esPic4.png';
import esPic5 from '../../assets/es/flow/esPic5.png';
import esPic6 from '../../assets/es/flow/espic6.png';
import esPic7 from '../../assets/es/flow/espic7.png';
import atpic1 from '../../assets/at/flow/atpic1.png';
import atpic2 from '../../assets/at/flow/atpic2.png';

const mapDispatchToProps = (dispatch) => ({
  dispatchTags: (tags) => dispatch(addTags(tags)),
});

function YesOrNoSelection({
  stepDescription,
  handleBack,
  options,
  handleClick,
  dispatchTags,
}) {
  const { t } = useTranslation();
  const history = useHistory();

  const divWrapperClassesTable = 'border-solid border-2 border-medtronic ';
  const imgWrapperClasses =
    'object-contain h-48 w-96 inline-block align-middle';

  const goCalendar = (optionSelected) => {
    if (optionSelected?.skipSummary == true) {
      //! from box to calendar directly
      try {
        dispatchTags(optionSelected.flowTags);
      } catch (err) {
        console.log('err', err);
      } finally {
        history.push(routes.calendar);
      }
    } else {
      handleClick(optionSelected);
    }
  };

  if (!options) {
    return <></>;
  }

  return (
    <div className=''>
      {options.some((e) => e.ESTextSize === true) && (
        <TextFrame handleBack={handleBack} render={t(stepDescription)}>
          {options.map((option, k) => (
            <div key={k} className={k === 0 ? 'mb-2' : ''}>
              <div className='mr-2 ml-2'>
              <p
                className='w-full h-12 cursor-pointer text-white text-xs flex items-center justify-center text-center font-extrabold bg-blue-gray hover:bg-primary '
                onClick={() => goCalendar(option)}
              >
                {t(option.title)}
              </p>
              </div>
            </div>
          ))}
        </TextFrame>
      )}
      {options.some((e) => e.ESTextSize !== true) && (
        <TextFrame handleBack={handleBack} render={t(stepDescription)}>
          {options.map((option, k) => (
            <div key={k} className={k === 0 ? 'mb-2' : ''}>
              <div className='mx-8 '>
                <Button
                  color='primary'
                  className='px-8'
                  size='large'
                  onClick={() => goCalendar(option)}
                  style={{ borderRadius: 25 }}
                  variant={k === 0 ? 'outlined' : 'contained'}
                >
                  {t(option.title)}
                </Button>
              </div>

              {/* <p
                // className='w-full h-12 cursor-pointer text-white text-xl flex items-center justify-center text-center font-extrabold bg-blue-gray hover:bg-primary md:h-28'
                onClick={() => goCalendar(option)}
              >
                {t(option.title)}
              </p> */}
            </div>
          ))}
        </TextFrame>
      )}
      {options.some((e) => e.ESshouldDisplayImages === true) && (
        <div>
          <br />
          <p>Para la formación necesitarás:</p>
          <div className='grid grid-flow-col text-center'>
            <table className={divWrapperClassesTable}>
              <thead>
                <tr>
                  <th className={divWrapperClassesTable}>
                    Bolígrafo inteligente InPen
                  </th>
                  <th className={divWrapperClassesTable}>Sensores Inpen</th>
                  <th className={divWrapperClassesTable}>Transmisor</th>
                  <th className={divWrapperClassesTable}>
                    Dispositivo para insertar sensor
                  </th>
                  <th className={divWrapperClassesTable}>
                    Teléfono móvil para las aplicaciones
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className={divWrapperClassesTable}>
                    <img
                      className={imgWrapperClasses}
                      src={esPic1}
                      alt='esPic1'
                    />
                  </td>
                  <td className={divWrapperClassesTable}>
                    <img
                      className={imgWrapperClasses}
                      src={esPic2}
                      alt='esPic2'
                    />
                  </td>
                  <td className={divWrapperClassesTable}>
                    <img
                      className={imgWrapperClasses}
                      src={esPic3}
                      alt='esPic3'
                    />
                  </td>
                  <td className={divWrapperClassesTable}>
                    <img
                      className={imgWrapperClasses}
                      src={esPic4}
                      alt='esPic4'
                    />
                  </td>
                  <td className={divWrapperClassesTable}>
                    <img
                      className={imgWrapperClasses}
                      src={esPic5}
                      alt='esPic5'
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      )}

      {options.some((e) => e.ESshouldDisplayImages2 === true) && (
        <div className='grid grid-flow-col margin-right'>
          <table>
            <tbody>
              <tr>
                <td align='right'>
                  <img
                    className={imgWrapperClasses}
                    src={esPic6}
                    alt='esPic6'
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      )}
      {options.some((e) => e.ESshouldDisplayImages3 === true) && (
        <div className='grid grid-flow-col margin-right'>
          <table>
            <tbody>
              <tr>
                <td align='right'>
                  <img
                    className={imgWrapperClasses}
                    src={esPic7}
                    alt='esPic7'
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      )}
      {options.some((e) => e.ATshouldDisplayImage === true) && (
        <div className='grid grid-flow-col margin-right'>
          <table>
            <tbody>
              <tr>
                <td align='left'>
                  <img
                    className={imgWrapperClasses}
                    src={atpic1}
                    alt='atpic1'
                  />
                  <img
                    className={imgWrapperClasses}
                    src={atpic2}
                    alt='atpic2'
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
}

YesOrNoSelection.propTypes = {
  options: PropTypes.array.isRequired,
  stepDescription: PropTypes.string,
  handleBack: PropTypes.func,
  handleClick: PropTypes.func,
  dispatchTags: PropTypes.any,
};

YesOrNoSelection.defaultProps = {
  stepName: '',
  options: [],
};

// export default YesOrNoSelection;
export default connect(null, mapDispatchToProps)(YesOrNoSelection);
